import {
  IHttpClient,
  IPlatformServices,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { getRoles as getMemberRoles } from '@wix/ambassador-members-v1-role/http';

import {
  GetMyMemberResponse,
  Member,
  MemberData,
  MemberCache,
  RolesMap,
  WixCodeApi,
} from '../../types';
import { USER_NAME_PATTERN } from '../../constants';
import { logError } from '../../utils/monitoring';
import { getMemoryStorage } from './memory-storage';
import { HttpClientService } from './httpClient';
import {
  getMemberActivityCounters,
  MemberActivityCounters,
} from './activity-counters-service';

const CURRENT_MEMBER_CACHE_STORAGE_KEY = 'current-member-cache';
const CURRENT_USER_ID_STORAGE_KEY = 'current-user-id';

export function createUserService(
  wixCodeApi: WixCodeApi,
  biService: IPlatformServices['bi'],
  flowAPI: ViewerScriptFlowAPI,
) {
  let viewedUser: Member, currentUser: Member, userRoles: RolesMap;

  function fetchMenuCounters(
    user: Member,
  ): Promise<{ apps: MemberActivityCounters }> {
    if (!user || user.loggedIn === false) {
      return Promise.resolve({ apps: [] });
    }
    return new Promise<{ apps: MemberActivityCounters }>((resolve, reject) => {
      const userId = user && user.id;
      if (userId) {
        getMemberActivityCounters({
          memberId: userId,
          loggedIn: !!user.loggedIn,
          biService,
          flowAPI,
          wixCodeApi,
        }).then((response) => resolve({ apps: response }));
      } else {
        reject(new Error('No user to get menu counters by'));
      }
    });
  }

  async function fetchRoles(
    viewedUserId: string,
    loggedInUserId: string,
    httpClient: IHttpClient,
  ): Promise<RolesMap> {
    const [viewedRoleResponse, loggedInRoleResponse] = await Promise.all([
      viewedUserId
        ? httpClient
            .request(getMemberRoles({ memberId: viewedUserId }))
            .catch(() => undefined)
        : undefined,
      loggedInUserId
        ? httpClient
            .request(getMemberRoles({ memberId: loggedInUserId }))
            .catch(() => undefined)
        : undefined,
    ]);

    return {
      ...(viewedRoleResponse && {
        [viewedUserId]:
          viewedRoleResponse?.data?.roles?.map(
            (role) => role.roleKey?.toLocaleLowerCase()!,
          ) || [],
      }),
      ...(loggedInRoleResponse && {
        [loggedInUserId]:
          loggedInRoleResponse?.data?.roles?.map(
            (role) => role.roleKey?.toLocaleLowerCase()!,
          ) || [],
      }),
    };
  }

  function replaceUserPatternWithSlug(url: string, user: Member) {
    return url
      .replace(USER_NAME_PATTERN, user.slug)
      .replace(encodeURI(USER_NAME_PATTERN), user.slug);
  }

  function getViewedUser() {
    return viewedUser ?? {};
  }

  function getCurrentUser() {
    return currentUser ?? {};
  }

  function getRoles() {
    return userRoles ?? {};
  }

  function setRoles(roles: RolesMap) {
    userRoles = roles;
  }

  function setViewedUser(userData: Member) {
    if (userData) {
      viewedUser = userData;
    }
  }

  async function setCurrentUser(userData: MemberData, httpClient: IHttpClient) {
    const memberCache = await getCurrentMemberCache(userData, httpClient);

    currentUser = {
      id: userData.id,
      loggedIn: userData.loggedIn,
      slug: memberCache?.slug ?? userData.id,
      contactId: memberCache?.contactId ?? userData.id,
    };
  }

  async function fetchCurrentMember(httpClient: IHttpClient) {
    const httpClientService = new HttpClientService(httpClient);
    const response = await httpClientService.get<GetMyMemberResponse>(
      '/_api/members/v1/members/my',
    );
    return (response as GetMyMemberResponse).member;
  }

  function getCurrentMemberCache(
    userData: MemberData,
    httpClient: IHttpClient,
  ): Promise<MemberCache | undefined> {
    const memoryStorage = getMemoryStorage();
    const storageMemberCache = memoryStorage.getItem(
      CURRENT_MEMBER_CACHE_STORAGE_KEY,
    );
    const currentUserId = memoryStorage.getItem(CURRENT_USER_ID_STORAGE_KEY);

    if (storageMemberCache && currentUserId === userData.id) {
      return Promise.resolve(JSON.parse(storageMemberCache) as MemberCache);
    }

    if (!userData.loggedIn || wixCodeApi.window.viewMode !== 'Site') {
      return Promise.resolve(undefined);
    }

    return fetchCurrentMember(httpClient)
      .then((member) => {
        const memberCache = {
          contactId: member?.contactId!,
          slug: member?.profile?.slug!,
        };

        memoryStorage.setItem(
          CURRENT_MEMBER_CACHE_STORAGE_KEY,
          JSON.stringify(memberCache),
        );

        return memberCache;
      })
      .catch(() => {
        logError('Error while fetching current member', {
          userDataId: userData.id,
        });

        return undefined;
      });
  }

  return {
    getCurrentUser,
    setCurrentUser,
    getViewedUser,
    setViewedUser,
    fetchRoles,
    getRoles,
    setRoles,
    fetchMenuCounters,
    replaceUserPatternWithSlug,
  };
}

export type UserService = ReturnType<typeof createUserService>;
